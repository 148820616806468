import {
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React, {
  useState
} from "react";
import axios from "axios";
import Dinero from 'dinero.js';
export const API_URL ='https://api.ownthisnumber.com';
// export const API_URL = 'http://localhost:8000';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  }
});

  const buyNumber = (data, value) => {
    try {
      return api.post(`${API_URL}/buy/${value}/`, data)
    } catch (error) {
      console.log(error);
    }
  };

  const queryNumber = value => {
    try {
      // console.log(api.get(`${API_URL}/${value}/`));
      return api.get(`${API_URL}/${value}/`)
    } catch (error) {
      console.log(error);
    }
  }

export const CheckoutForm = (number, name, quote) => {
    const [error, setError] = useState(null);
    const [checkoutMessage, setCheckoutMessage] = useState("");
    const [checkoutComplete, setCheckoutComplete] = useState(false);
    const [email, setEmail] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    // console.log('CHECKOUT DICT', number)
    // console.log('CHECKOUT NUMBER', number.number)
    // console.log('CHECKOUT NAME', number.name)
    // console.log('CHECKOUT QUOTE', number.quote)

    const handleChange = (event) => {
      if (event.error) {
        setError(event.error.message);
      } else {
        setError(null);
      }
    }

    const calculateNumber = (value) => {
      if (value >= 0 && value <= 30) {
        return "£1";
      } 
      else if (value >= 100000) {
        return `${Dinero({amount: value, currency: 'GBP' }).toFormat('$0,0.00')}`;
      } else {
        return `${Dinero({amount: value, currency: 'GBP' }).toFormat('$0,0.00')}`;
      }
    };


    // Handle form submission.
    const handleSubmit = async (event) => {
      event.preventDefault();
      const card = elements.getElement(CardElement);
      const {
        paymentMethod,
        error
      } = await stripe.createPaymentMethod({
        type: 'card',
        card: card
      });
      try {
        // console.log(paymentMethod);      
        // console.log('BUTTON EMAIL', email);
        // console.log('BUTTON NAME', number.name);
        // console.log('BUTTON QUOTE', number.quote);
            queryNumber(number.number)
              .then(response => {
                if (response.status === 204) {
                  buyNumber({
                    email:email,
                    name:number.name,
                    quote: number.quote,
                    payment_method_id: paymentMethod.id,
                  }, number.number)
                    .then(response => {
                      if (response.data.message === 'Success') {
                        // console.log('buyNumber returned success response, number has been bought')
                        setCheckoutMessage("Number successfully bought!");
                        setCheckoutComplete(true);
                      } 
                     })
                      } else {
                        setCheckoutMessage("Something went wrong!");
                      }
                            }
                  )

        } catch (error) {
          console.log(error);
          setCheckoutMessage(error.message);
          return error.response;
        }
      };
  

    return ( <div>
      <form onSubmit = {handleSubmit} className = "" >
      <div >
      <ul >
      </ul> </div > 
      <div className = "flex flex-col" >
      <label className = "label-small"
      htmlFor = "email" > Email Address </label> 
      <input className = "input-large"
      id = "email"
      name = "name"
      type = "email"
      placeholder = "person@example.com"
      required value = {
        email
      }
      onChange = {
        (event) => {setEmail(event.target.value)}}/> </div> 
      <div className = "form-row" >
      <label className = "label-small" htmlFor = "card-element"> Credit or debit card </label>
      <CardElement className = "text-purple-600"
      id = "card-element"
      onChange = {handleChange}/> 
      <div className = "card-errors" role = "alert" > {error} </div> 
      </div> 
      <div className = "flex flex-col justify-center">
      <button type = "submit"
      className = "submit-btn bg-purple-400 hover:bg-purple-600 text-white p-4 rounded-md m-6" >
      Pay {calculateNumber(number.number)} now </button> <label className = {checkoutComplete ? "text-green-600 text-center p-2" : "text-red-600 text-center p-2"}> {checkoutMessage} </label> 
      <label className = "label-small text-center text-purple-600" > {checkoutComplete ? < a href = {`https://ownthisnumber.com/${number.number}`} > Click to view! < /a> : ""}</label >
        </div> 
        </form> 
        </div>
      );
    };

    export default CheckoutForm;