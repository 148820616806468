import React, {useState, useEffect} from 'react';
import {BrowserRouter, Switch, Route, Link, Redirect} from 'react-router-dom';
import Dinero from 'dinero.js';
import ImageUploader from 'react-images-upload';
import './App.css';
import './tailwind.output.css';
// import logo from './logo.svg';
import {loadStripe} from '@stripe/stripe-js';
import NumberPage from './NumberPage';
import ExplainerPage from './ExplainerPage';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './components/CheckoutForm';

import LogRocket from 'logrocket';
LogRocket.init('ihn5qd/ownthisnumber');

//const stripePromise = loadStripe('pk_test_51HIZMvKxcHjxbsmGXFHDaNiZ16I9dA55jsbHZyKIH4v5HlyQktqPyiWZjUlMBqi6YjnmLnNa03XMicgTPPy74ycV00qigGEc3K');

const stripePromise = loadStripe('pk_live_51HIZMvKxcHjxbsmGVwDqBiQOJZ7onGjcWn6iXxhOnUtZVwhrmecBSZI7ph4a4KJn6kFgmxwLLKvXcMjkvgiNrERm00vkkXNLcx');

const App = () => {
  const [number, setNumber] = useState(0);
  const [name, setName] = useState('');
  const [quote, setQuote] = useState('');
  const [picture, setPicture] = useState(null);
  const [error, setError] = useState('');
  const [takenValues, setTakenValues] = useState([]);
  
  // console.log('APP NAME', name)
  // console.log('APP NUMBER', number)
  // console.log('APP QUOTE', quote)


  const getNumbers = () => {
    return fetch('https://api.ownthisnumber.com/numbers/')
    .then(response => response.json())
    .then(data => setTakenValues(data.map(a => a.value).sort((a, b) => a - b)))
    .catch(error => setError(error))
  };
  
  useEffect(() => {
    if (number === 0) {
      document.title = `Own a number!`
    }
    else {
      document.title = `Own the number ${number}`
    }
  });
  
  useEffect(() => {
    getNumbers()
    return () => {
    }
  }, [number]);


  const calculateCost = (value) => {
      if (value >= 0 && value <= 30) {
        return "The number is available and costs £1!";
      }
      else if (value >= 100000){
        return `${Dinero({amount: value, currency: 'GBP'}).toFormat('$0,0.00')} is a really large number, are you sure you want to buy?`;
      }
      else {
        return `The number is available and costs ${Dinero({amount: value, currency: 'GBP'}).toFormat('$0,0.00')}`;
      }
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <div className="App bg-gray-100 sm:my-4 m-auto max-w-md rounded-sm" >
          <div className="flex flex-col sm:my-10 mx-10 rounded-md">
              <h1 className="mt-8 mb-2 text-4xl font-semibold text-purple-600"> Own This Number </h1>
              <h2 className="text-xl text-gray-600"> But pay the number's value in pennies.* </h2>
              <h3 className="mb-8 text-sm text-gray-400"> *Except for numbers 1-30, those cost £1 each.</h3>
              <div className="flex flex-col">
                <label className="label-large" htmlFor="">Pick your number</label>
                <label className="label-small" htmlFor="">Pick whichever number you want and see if it's available.</label>
                <input className="input-large input-number mb-0" type="number" min="0" name="numberinput" value={number.toString()} onChange={e => setNumber(Math.abs(e.target.value))} />
              <div className="availability mb-8">
                  {!takenValues.includes(number) ? <label className="label-small text-green-600 ">{calculateCost(number)}</label> : <label className="label-small text-red-600 ">This number is taken, sorry!</label>}
              </div>
              </div>
              <div className="flex flex-col">
                <label className="label-large" htmlFor="">Add a name</label>
                <label className="label-small" htmlFor="">This can be your name, the name of the person you're buying for, or the names of you and your partner.</label>
                <input className="input-large input-name" name="nameinput" value={name} onChange={e => setName(e.target.value)} />
              </div>
              <div className="flex flex-col"> 
                <label className="label-large" htmlFor="">Add a quote</label>
                <label className="label-small" htmlFor="">This can be anything you want (I trust you to keep it PG). </label>
                <input className="input-large" name="quotepicker" value={quote} onChange={e => setQuote(e.target.value)} />
              </div>
              <div div className="flex flex-col py-8">
                <Elements stripe={stripePromise} >
                  <CheckoutForm number={Math.abs(number)} name={name} quote={quote} calculateCost={calculateCost(number)}/>
                </Elements>
              </div>
            </div>
          </div>
      </Route>
      <Route exact path="/howitworks" component={ExplainerPage} />
      <Route exact path="/:id" component={NumberPage} />
    </Switch>
    </BrowserRouter>
  );
}

export default App;
