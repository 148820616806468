import React, {useEffect, useState} from "react";

const ExplainerPage = () => {


return (
    <div className="flex justify-center p-16">
        <div className="max-w-xl">
            <h1 className="text-purple-600 text-lg lg:text-3xl font-bold text-center">How it works</h1>
            <h3 className="font-regular text-lg mt-4 text-gray-500">When you buy a number on this website, what you're buying is a subpage.</h3>
            <h3 className="font-regular text-lg mt-4 text-gray-500">If, for example, you buy the number 42, then you get to add a name and a quote to <b><a href="https://ownthisnumber.com/42">ownthisnumber.com/42</a></b></h3>
            <h3 className="font-regular text-md lg:text-lg text-gray-500 mt-8">This page remains there for the lifetime of the website.</h3>
            <h3 className="font-regular text-md lg:text-lg text-gray-500 mt-8">The amount you pay for the number/subpage is the cost of the number itself in pennies, with the exception of numbers 1-30, because the payment processor literally cannot charge less than 30p.</h3>
            <h3 className="font-regular text-md lg:text-lg text-gray-500 mt-8">Your payments are all secure - the payment process is PCI-compliant and handled by <b><a href="https://stripe.com">Stripe</a></b>, the biggest and most reputable payment processor on the Internet. (For those who care, the payment takes place in a secure iframe, your credit card information never touches my server.)</h3>
            <h3 className="font-regular text-md lg:text-lg text-gray-500 mt-8">You can (and should) read the <b><a href="https://ownthisnumber.com/terms">Terms and Conditions</a></b> and <b><a href="https://ownthisnumber.com/privacy">Privacy Policy</a></b>, even though they basically say the same thing as in this explainer, just in more legalese. I'm a privacy nerd, and want to store as little information about the end user as possible. The only information that's stored is what you upload, your email (required for a payment receipt) and the information in the server logs (basically your IP and what browser you use).</h3>
            <h3 className="font-regular text-md lg:text-lg text-purple-600 mt-8">If you have any questions, you can reach me at <b>alex@harston.io</b>, or DM me on Twitter at <b><a href="https:twitter.com/alexharston">@alexharston</a></b></h3>
        </div>
    </div>
)
};


export default ExplainerPage;