import React, {useEffect, useState} from "react";

const API_URL = "https://api.ownthisnumber.com"
// const API_URL = "http://localhost:8000"

const NumberPage = ({match}) => {
        const [details, setDetails] = useState([]);
        const [takenValues, setTakenValues] = useState([]);
        
        useEffect(() => {
          fetch(`${API_URL}/numbers/`)
          .then(data => data.json())
          .then(response => setTakenValues(response.map(item => item.value)))
        }, [])

        useEffect(() => {
          fetch(`${API_URL}/${match.params.id}/`)
          .then(data => data.json())
          .then(response => setDetails(response));
        }, [match.params.id])
       
          if (takenValues.includes(Number(match.params.id))) {
            return (
              <div className="flex flex-col pt-16 px-8 justify-center">
                <h1 className="text-left md:text-center text-2xl"><b className="text-purple-600">{details.value}</b> is owned by <b className="text-purple-600">{details.owner}.</b></h1> 
                <h2 className="text-2xl text-purple-600 mt-8 text-left md:text-center">"{details.quote}"</h2>
              </div>
            );
          }
          else if (Number.isInteger((Number(match.params.id)))){
            return(
              <div className="flex pt-16 px-4 justify-center">
                <h1 className="text-grey-400 text-3xl max-w-xl text-center"><b>{match.params.id}</b> is still available - would you like <a className="text-purple-600 font-bold"href="/">to own it?</a></h1> 
              </div>
              );
          }

          else {
            return(
              <div className="flex pt-16 px-4 justify-center">
              <h1 className="text-grey-400 text-3xl max-w-xl text-center"><b>{match.params.id}</b> is not a number! Please try again with an actual value.</h1> 
              </div>
            )
          }
      }

export default NumberPage;